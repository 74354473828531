import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import IconLinkArrowSvg from '../images/icons/link-arrow.svg'
import { BREAKPOINTS, ANIMATION } from '../styles/cssVariables'
import { isAbsoluteUrl } from '../utils/utils'
import { Link } from 'gatsby'

const Title = styled.h2`
  margin-bottom: 30px;

  
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    width: 100%;
    margin-bottom: 10px;
  }
  // @media (max-width: 680px) {
  //   margin-bottom: 10px;
  // }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    // text-align: unset;
    // margin-bottom: 28px;
  }
`

const Grid = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  // margin-bottom: 20px;
  @media (max-width: 680px) {
    flex: unset;
    flex-flow: unset;
    display: block;
    
  }
`

const LogoLink = styled.a`
  flex: 1;
  cursor: pointer;
  @media (max-width: 680px) {
      flex: unset;
      display: block;
      width: 100%;
      margin-bottom: 50px;
      &:first-child {
        margin-bottom: 15px;
      }
    }
`

const Logo = styled(GatsbyImage)`

  margin-left: 30px;
  margin-right: 30px;


  @media (max-width: 1209px) {
    margin-left: 10px;
    margin-right: 10px;
  }
  @media (max-width: 680px) {
    width: 35%;
    margin-left: 0;
    margin-right: 0; 
    margin-left: auto;
    margin-right: auto; 

  }
`

const LogoCTAButton = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--color-skobeloff);
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 25px;
  background-color: var(--color-skobeloff) !important;
  color: var(--color-white) !important;
  
  margin-left: auto;
  margin-right: auto;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    color: var(--color-skobeloff) !important;
    background-color: transparent !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 50px;
  }
  
`

const LogoCTAButtonGatsbyLink = styled(Link)`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--color-skobeloff);
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 2Y5px;
  background-color: var(--color-skobeloff) !important;
  color: var(--color-white) !important;
  margin-left: auto;
  margin-right: auto;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    color: var(--color-skobeloff) !important;
    background-color: transparent !important;  
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 50px;
  }
  
`
const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`
/**
 * Displays a grid of logos.
 * Optional heading, subheading, and CTA.
 */
const LogoGrid = (props) => {
  return (
    <>
      {props.title && <Title>{props.title}</Title>}

      <Grid>
        {props.logos.map((logo) => (
          <LogoLink 
          href={logo.description} 
          target="_blank"
          rel="nofollow"
          key={logo.id}
          >
            <Logo key={logo.id} alt={logo.title} image={logo.gatsbyImageData} />
          </LogoLink>
        ))}
      </Grid>

      {props.ctaText && (
      <>
        {isAbsoluteUrl(props.ctaDestination) ? (
          <LogoCTAButton
            href={props.ctaDestination}
            target="_blank"
            rel="nofollow"
          >
            {props.ctaText}
            <Arrow />
          </LogoCTAButton>
        ) : (
          <LogoCTAButtonGatsbyLink to={props.ctaDestination}>
            {props.ctaText}
            <Arrow />
          </LogoCTAButtonGatsbyLink>
        )}   
      </>
  )}
  
  </>
  )
}

LogoGrid.propTypes = {
  /** Optional CTA link destination */
  ctaDestination: PropTypes.string,
  /** Optional CTA link text */
  ctaText: PropTypes.string,
  /** Logo files */
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      gatsbyImageData: PropTypes.object,
    })
  ).isRequired,
  /** Optional subheading */
  /** Optional heading */
  title: PropTypes.string,
}

export default LogoGrid
